
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseStep from "./BaseStep";
import UploadPicture from "@/panel/UploadPicture.vue";
import { AccountType } from "../../register/registerModel";
import store from "../../store";

@Component({
  components: {
    UploadPicture,
  },
})
export default class UploadPhotoStep extends BaseStep {
  canGoNext(): boolean {
    return store.getters.module.user!.accountType == AccountType.Client || (this.$refs.uploadPicture as UploadPicture).hasAny;
  }
  get title() {
    return this.$t("Upload photo").toString();
  }
  save(): Promise<void> {
    return Promise.resolve();
    // nothing to do here
  }

  mounted() {
    super.mounted();
  }

  shouldBeDisplayed(): boolean {
    return true;
  }
}
