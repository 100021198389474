
import { getEducationLevelOptionsTranslations } from "@/accountSetup/educationLevelHelper";
import { Component } from "vue-property-decorator";
import BaseStep from "./BaseStep";
import { AccountType } from "@/register/registerModel";
import { EducationLevel, Gender, MaritalStatus, saveAccountSetup } from "../accountSetupService";
import store from "../../store";
import { LoggedUserInformation } from "../../login/loginModel";
import birthDatepicker from 'vue-birth-datepicker/src/birth-datepicker';
import SelectHeight from '../../assistant/SelectHeight.vue'
import { getHeightInOtherScale, HeightScale } from "../../accountSetup/assistantHeightHelper"
import { getMaritalStatusOptionsTranslations } from "../maritalStatusHelper";
import UploadPhotoStep from "@/accountSetup/steps/UploadPhotoStep.vue";

interface BasicInfoStepData {
  hourlyRate?: number;
  description?: string;
  firstName?: string;
  lastName?: string;
  gender?: Gender;
  maritalStatus?: MaritalStatus;
  height?: number,
  birthDate?: number;
  educationLevel?: EducationLevel;
  educationDetails?: string;
}

@Component({
  components:{
    birthDatepicker,
    SelectHeight,
    UploadPhotoStep
  }
})
export default class BasicInfoStep extends BaseStep {
  private form: BasicInfoStepData = {};

  get isAssistant(): boolean {
    return store.getters.module.user!.accountType === AccountType.Assistant;
  }

  get higherEducationLevel() { return EducationLevel.Higher; }
  get studentEducationLevel() { return EducationLevel.Student; }
  get otherEducationLevel() { return EducationLevel.Other; }

  rawHeight: { value?: number, scale: HeightScale } = { scale: 'cm'}

  get title() {
    return this.$t("Basic information").toString();
  }

  save(): Promise<any> {
    return saveAccountSetup(store.getters.module.user!.id, {
      hourlyRate: this.form!.hourlyRate!,
      description: this.form!.description!,
      firstName: this.form!.firstName!,
      lastName: this.form!.lastName!,
      birthDate: this.form!.birthDate ? new Date(this.form!.birthDate!) : undefined,
      educationLevel: this.form!.educationLevel,
      educationDetails: this.form!.educationDetails,
      gender: this.form!.gender,
      height: !!this.rawHeight.value ? getHeightInOtherScale(this.rawHeight.value, this.rawHeight.scale, 'cm') : undefined,
      maritalStatus: this.form.maritalStatus,
    }).then(() => {
      store.dispatch.module.updateStoredUser(
        { ...this.form!, ...{ birthDate: this.form.birthDate ? new Date(this.form.birthDate).toString() : undefined } }).catch(() => { /* noop */ });
    });
  }

  get hasFullName() {
    return !!this.form!.firstName && !!this.form!.lastName;
  }

  get hasHourlyRate() {
    return this.form!.hourlyRate !== undefined && this.form!.hourlyRate! > 0;
  }

  canGoNext(): boolean {
    return this.hasFullName &&
      ((!this.isAssistant) || (this.hasHourlyRate));
  }

  shouldBeDisplayed(): boolean {
    return true;
  }

  mounted() {
    const storeUser = store.getters.module.user! as LoggedUserInformation;

    this.form = {
      hourlyRate: storeUser.hourlyRate,
      description: storeUser.description,
      firstName: storeUser.firstName,
      lastName: storeUser.lastName,
      birthDate: storeUser.birthDate ? Date.parse(storeUser.birthDate!) : undefined,
      educationLevel: storeUser.educationLevel,
      educationDetails: storeUser.educationDetails,
      gender: storeUser.gender,
      height: storeUser.height,
      maritalStatus: storeUser.maritalStatus,
    };

    this.rawHeight = { value: storeUser.height, scale: 'cm' }

    super.mounted();
  }

  get genderOptions() {
    return [
      { text: this.$t("Female"), value: Gender.Female },
      { text: this.$t("Male"), value: Gender.Male },
      { text: this.$t("Do not set"), value: null },
    ]
  }

  get educationLevelOptions() {
    return getEducationLevelOptionsTranslations(this)
  }

  get maritalStatusOptions() {
    return getMaritalStatusOptionsTranslations(this)
  }
}
