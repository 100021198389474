
import { Component } from "vue-property-decorator";
import BaseStep from "./BaseStep";
import SelectCategories from "./SelectCategories.vue";
import SelectLanguages from "../../assistant/SelectLanguages.vue";

@Component({
  components: {
    SelectCategories,
    SelectLanguages,
  },
})
export default class CategoriesAndLanguages extends BaseStep {
  get title() {
    return this.$t("Categories and languages").toString();
  }

  get categoriesForm() {
    return this.$refs.formCategories as SelectCategories;
  }

  canGoNext(): boolean {
    return this.categoriesForm.selectedAmount > 0;
  }

  mounted() {
    super.mounted();
  }

  async save(): Promise<void> {
    await this.categoriesForm.onSubmit();
    await (this.$refs.formLanguages as SelectLanguages).onSubmit();
  }

  shouldBeDisplayed(): boolean {
    return true;
  }
}
