
import { updateUserCategories } from "@/assistant/userCategoryWebServices";
import { pushError } from "@/core";
import {
  Category,
  CategoryGroup,
  fetchCategories,
} from "@/serviceClients/categoryWebServices";
import store from "@/store";
import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class SelectCategories extends Vue {
  @Prop() hasExternalSaveButton!: boolean;

  form: string[] = [];
  private allCategoryGroups: CategoryGroup[] = [];
  selectedSkillOnline = 1;
  selectedGroup = "";
  isLoading = false;

  setCategory(value: number) {
    this.selectedSkillOnline = value;
  }
  setVisibleGroup(id: string) {
    this.selectedGroup = id;
  }

  get selectedAmount() {
    return this.form.length;
  }

  async mounted() {
    this.$watch(
      () => store.getters.module.user!.skillIds,
      () => {
        this.form = store.getters.module.user!.skillIds;
      }
    );
    this.form = store.getters.module.user!.skillIds;
    try {
      this.isLoading = true;
      const result = await fetchCategories();
      this.allCategoryGroups = result.data;
      this.selectedGroup = this.categoryGroups ? this.categoryGroups[0].id : "";
    } catch (error) {
      pushError((error as any).response?.data?.error || error);
    } finally {
      this.isLoading = false;
    }
  }

  async onSubmit() {
    try {
      this.isLoading = true;

      await updateUserCategories(store.getters.module.user!.id, this.form);
      await store.dispatch.module.updateStoredUser({ skillIds: this.form });
      this.$el.children[0].scrollIntoView();
    } catch (error) {
      pushError((error as any).response?.data?.error || error);
    } finally {
      this.isLoading = false;
    }
  }

  get categoryGroups(): CategoryGroup[] {
    return this.allCategoryGroups.filter(
      (c) => c.serviceType === this.selectedSkillOnline
    );
  }
}
