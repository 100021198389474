
import { Component, Vue } from "vue-property-decorator";
import BasicInfoStep from "./steps/BasicInfoStep.vue";
import SelectAccountStep from "./steps/SelectAccountStep.vue";
import BaseStep from "./steps/BaseStep";
import InterestsStep from "./steps/InterestsStep.vue";
import InvoiceInfoStep from "./steps/InvoiceInfoStep.vue";
import AddressStep from "./steps/AddressStep.vue";
import CategoriesAndLanguages from "./steps/CategoriesAndLanguages.vue";
import { pushError } from "@/core";
import store from "../store";
import { AccountType } from "../register/registerModel";

interface IStepInfo {
  component: typeof BaseStep;
  object: BaseStep | undefined;
  props?: any;
}

@Component
export default class AccountSetup extends Vue {
  private get maxStep() {
    for(let index = this.steps.length - 1; index >=0; index--) {
      if(this.steps[index].object?.shouldBeDisplayed()) {
        return index;
      }
    }
    return 0;
  };
  private get minStep() {
    for(let index in this.steps) {
      if(this.steps[index].object?.shouldBeDisplayed()) {
        return index;
      }
    }
    return 0;
  };
  private step = -1;
  private isLoading = false;

  private steps: IStepInfo[] = [
    { component: SelectAccountStep, object: undefined },
    { component: BasicInfoStep, object: undefined },
    { component: AddressStep, object: undefined },
    { component: CategoriesAndLanguages, object: undefined },
    { component: InvoiceInfoStep, object: undefined },
    { component: InterestsStep, object: undefined }
  ]

  mountedCallback(index: number, step: BaseStep) {
    this.steps[index].object = step;
  }

  mounted() {
    this.goToNextDisplayedStep();
  }

  goToNextDisplayedStep() {
    do {
      if(this.step < this.maxStep) {
        this.step++;
      } else {
        this.$router.push("/panel").catch(() => { /* noop */ });
        return;
      }
    } while(this.steps[this.step].object && !this.steps[this.step].object?.shouldBeDisplayed());
  }

  get accountType() {
    return store.getters.module.user?.accountType
  }

  get stepsToDisplay() {
    if(store.getters.module.user?.accountType == AccountType.Unknown) {
      return [];
    }
    return this.steps
    .filter(s => s.object?.shouldBeDisplayed());
  }

  get currentStepTitle() {
    const currentStep = this.steps[this.step]

    if (currentStep) {
      return currentStep.object? currentStep.object.title: '';
    }

    return ''
  }

  onNext() {
    this.loading();
    this.steps[this.step].object!.save()
      .then(() => {
        this.goToNextDisplayedStep();
      })
      .finally(() => {
        this.loaded();
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error, { title: this.$t("An error occurred while saving") });
      });
  }

  private loading() {
    this.isLoading = true;
  }

  private loaded() {
    this.isLoading = false;
    window.scrollTo(0, 0);
  }

  get isNextEnabled(): boolean {
    return this.step >= 0 && !!this.steps[this.step].object && this.steps[this.step].object!.canGoNext();
  }

  onSave() {
    this.onNext();
  }

  onPrev() {
    if (this.step > this.minStep) {
      this.step--;
    }
  }
}
