
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseStep from "./BaseStep";
import store from "../../store";
import { CustomerInvoicingMode } from "../../login/loginModel";
import EditAddressForm from "@/panel/EditAddressForm.vue";
import { AccountType } from "../../register/registerModel";

@Component({
  components: {
    EditAddressForm
  },
})
export default class AddressStep extends BaseStep {
  isLoading = false;
  error: string = "";

  model: CustomerInvoicingMode = CustomerInvoicingMode.Unknown;

  save(): Promise<any> {
    return (this.$refs.form as EditAddressForm).onSubmit();
  }
  get title() {
    return this.$t("Your address").toString();
  }
  canGoNext(): boolean {
    return !!(this.$refs.form as EditAddressForm).isValid();
  }

  mounted() {
    super.mounted();
  }

  shouldBeDisplayed(): boolean {
    return true
  }

}
