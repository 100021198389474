import { EducationLevel } from './accountSetupService'

export const getEducationLevelOptionsTranslations = (vue: Vue) => {
    return [
        { value: EducationLevel.Higher, text: vue.$t('Higher') },
        { value: EducationLevel.Student, text: vue.$t('Student') },
        { value: EducationLevel.Other, text: vue.$t('Other') },
    ]
}

export const getEducationLevelOptionTranslation = (vue: Vue, value: EducationLevel) => {
    return getEducationLevelOptionsTranslations(vue).filter(o => o.value === value)[0].text;
}