
import { Component } from "vue-property-decorator";
import BaseStep from "./BaseStep";
import store from "../../store";
import SelectInterests from "@/assistant/SelectInterests.vue";
import {updateUserHobbies, updateUserInterests} from "../../assistant/userCategoryWebServices";
import { AccountType } from "../../register/registerModel";
import { pushError } from "@/core";
import SelectHobbies from "@/assistant/SelectHobbies.vue";

@Component({
  components: {
    SelectInterests,
    SelectHobbies
  },
})
export default class InterestsStep extends BaseStep {
  private interestIds: string[] = [];
  private hobbyIds: string[] = [];
  isLoading = false;
  get title() {
    return this.$t("Choose your interests.").toString();
  }
  save(): Promise<any> {
    this.isLoading = true;

    const updateInterest = updateUserInterests(store.getters.module.user!.id, this.interestIds)
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      })
      .finally(() => {
        this.isLoading = false;
      })

    const updateHobbies = updateUserHobbies(store.getters.module.user!.id, this.hobbyIds)
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      })
      .finally(() => {
        this.isLoading = false;
      });

    return updateInterest && updateHobbies
  }

  canGoNext(): boolean {
    return true;
  }

  mounted() {
    super.mounted();
  }

  shouldBeDisplayed(): boolean {
    return store.getters.module.user?.accountType === AccountType.Assistant;
  }
}
