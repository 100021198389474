
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseStep from "./BaseStep";
import store from "../../store";
import { CustomerInvoicingMode } from "../../login/loginModel";
import InvoiceDetailsForm from "@/panel/InvoiceDetailsForm.vue";

@Component({
  components: {
    InvoiceDetailsForm
  },
})
export default class InvoiceInfoStep extends BaseStep {
  isLoading = false;
  error: string = "";

  save(): Promise<any> {
    return (this.$refs.form as InvoiceDetailsForm).onSubmit();
  }
  get title() {
    return this.$t("Invoicing information.").toString();
  }
  canGoNext(): boolean {
    return true;
  }

  mounted() {
    super.mounted();
  }

  shouldBeDisplayed(): boolean {
    return store.getters.module.user?.customerInvoicingMode === CustomerInvoicingMode.FullVat;
  }

}
