
import { Component } from "vue-property-decorator";
import BaseStep from "./BaseStep";
import { AccountType } from "@/register/registerModel";
import { setAccountType } from "../../serviceClients/userWebServices";
import store from "@/store/index";
import { CustomerInvoicingMode } from "../../login/loginModel";
import SelectAccountType from "../../register/SelectAccountType.vue";
import SelectInvoicingMode from "../../panel/SelectInvoicingMode.vue";
import { saveCustomerInvoicingMode } from "../accountSetupService";

@Component({
  components: {
    SelectAccountType,
    SelectInvoicingMode
  },
})
export default class SelectAccountStep extends BaseStep {
  accountType: AccountType = AccountType.Unknown;
  invoicingMode: CustomerInvoicingMode = CustomerInvoicingMode.Unknown;
  get title() {
    return this.$t("Account type").toString();
  }
  canGoNext(): boolean {
    return this.accountType !== AccountType.Unknown;
  }
  save(): Promise<any> {
    return setAccountType(store.getters.module.user!.id, this.accountType).then(() => {
        store.dispatch.module.updateStoredUser({
          accountType: this.accountType,
        }).catch(() => { /* noop */ });
        if(this.accountType === AccountType.Client) {
          return saveCustomerInvoicingMode(store.getters.module.user!.id, this.invoicingMode)
            .then(() => {
              store.dispatch.module.updateStoredUser({
                customerInvoicingMode: this.invoicingMode,
              }).catch(() => { /* noop */ });
            });
        }
      }
    );
  }

  get showInvoicingMode() {
    return false;
  }

  mounted() {
    this.accountType = store.getters.module.user!.accountType;
    this.invoicingMode = store.getters.module.user!.customerInvoicingMode;
    super.mounted();
  }

  shouldBeDisplayed(): boolean {
    return store.getters.module.user?.accountType === AccountType.Unknown;
  }
}
