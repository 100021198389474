import { Prop, Vue } from "vue-property-decorator";
export default abstract class BaseStep extends Vue {
    @Prop() mountedCallback!: (component: BaseStep) => void;

    mounted() {
        this.mountedCallback(this);
    }

    abstract save() : Promise<any>;
    abstract canGoNext() : boolean;
    abstract shouldBeDisplayed() : boolean;
    abstract get title(): string;
}