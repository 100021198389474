import { getBaseServiceUrl } from '@/helpers/settingsHelper'
import * as axios from 'axios'

const url = `${getBaseServiceUrl()}/account/password`;

export interface ResetPasswordModel {
    resetPasswordRequestId: string;
    userId: string;
    newPassword: string;
}

export function requestResetPassword(emailAddress: string) {
    return axios.default.get<boolean>(`${url}/request-reset/${emailAddress}`);
}

export function resetPassword(data: ResetPasswordModel) {
    return axios.default.post<boolean>(`${url}/reset`, data);
}